<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Casse"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToDeviceSettings">
        Impostazioni Stampante
      </v-btn>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :external-filter="externalFilter"
          :show-filters="false"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          :show-select="false"
          loading-text="Caricamento casse ..."
          :headers="headers"
          :loading="loading"
          :items="filteredCashDesks"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :deletable="true"
          @edit="handleEdit"
          @delete="handleDelete"
          @update:page="fetchCashDesks"
          @update:row-per-page="fetchCashDesks"
          @dblclick:row="handleEditDoubleClick"
        >  
          <template v-slot:custom-button="{item}">
            <StandardMenu
              close-on-content-click
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn 
                  text 
                  link 
                  v-on="on" 
                  v-bind="attrs"
                >
                  <v-icon class="mr-2">mdi-cash-lock</v-icon> Chiusura fiscale
                </v-btn>
              </template>
              <v-card>
                <v-list>
                  <v-list-item 
                    @click="makeFiscalClosure(item)" 
                    link
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-printer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Stampa</v-list-item-content>
                  </v-list-item>
                  <v-list-item 
                    link
                    @click="openScheduleFiscalClosureDialog(item)"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-clock-time-eight-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>Programma</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </StandardMenu>
          </template>
          <template v-slot:extra-actions="{ item }">
            <v-btn icon @click.stop="() => handlePrintTextClick(item)">
              <v-icon small>mdi-printer</v-icon>
            </v-btn>
          </template>
        </TypeDataTable>
      </div>
      <StandardDialog
        v-model="scheduleFiscalClosureDialog"
        :dialog-height="null"
        dialog-width="400px"
        title="Programma chiusura fiscale"
      >
        <div class="d-flex align-center justify-center my-3">
          <TimePicker
            v-model="scheduleTime"
          ></TimePicker>
        </div>
        <v-alert
          border="left"
          colored-border
          color="warning"
          elevation="2"
          style="width: 100%"
          class="mb-2"
        >
          <div class="text-center">
            Se si imposta un orario vicino a quello corrente potrebbe non funzionare l'esecuzione automatica per la prima chiusura
          </div>
        </v-alert>
        <template v-slot:footer-actions>
          <v-btn
            text
            color="error"
            @click="scheduleFiscalClosureDialog = false"
          >
            Chiudi
          </v-btn>
          <v-btn
            text
            color="error"
            @click="scheduleFiscalClosureReset"
          >
            Resetta
          </v-btn>
          <v-btn
            text
            color="default"
            @click="scheduleFiscalClosure"
          >
            Programma
          </v-btn>
        </template>
      </StandardDialog>

      <StandardDialog
        v-model="printCustomTextDialog"
        :dialog-height="null"
        dialog-width="600px"
        title="Stampa testo personalizzato"
      >
        <v-textarea
          v-model="customText"
          class="mt-1"
          filled
          dense
          hide-details="auto"
          placeholder="Inserisci il testo da stampare"
        ></v-textarea>
        <v-text-field
          v-model="numberOfPrint"
          flat
          filled
          rounded
          label="Numero di stampe"
          hide-details="auto"
          class="mt-2"
          type="number"
        ></v-text-field>
        <template v-slot:footer-actions>
          <v-btn
            text
            color="error"
            @click="printCustomTextDialog = false"
          >
            Chiudi
          </v-btn>
          <v-btn
            text
            color="default"
            :loading="printLoading"
            @click="printCustomText"
          >
            Stampa
          </v-btn>
        </template>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import TimePicker from '@/components/common/TimePicker.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import cashDesksService from '@/services/cashDesks/cashDesks.service.js'
import Printer from '@/services/devices/rtPrinter'
import Sender from '@/services/devices/rtPrinter/sender.js'

export default {
  name: "CashDesksList",
  components: {
    FullScreenDialog,
    StandardDialog,
    AdvancedFilter,
    TypeDataTable,
    StandardMenu,
    TimePicker
  },
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      loadingDelete: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      cashDesks: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined,
      scheduleFiscalClosureDialog: false,
      cashDeskIP: undefined,
      scheduleTime: undefined,
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [],
      isLicenseBlocked: undefined,
      printCustomTextDialog: false,
      customText: undefined,
      printingCashDesk: undefined,
      numberOfPrint: undefined,
      printLoading: false
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchCashDesks()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchCashDesks() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      cashDesksService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.cashDesks = results.rows
        this.loadingItems = false
      })
    },
    applyFilters() {
      this.fetchCashDesks();
    },
    fetchHeaders() {
      this.loadingHeaders = true
      cashDesksService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleDelete(cashDesk) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + cashDesk.name + ' ?')

      if(confirmed) {
        this.loadingDelete = true
        cashDesksService.disable(cashDesk).then(() => {
          this.fetchCashDesks()
          this.loadingDelete = false
        })
      }
    },
    handleEdit(cashDesk) {
      this.$router.push({
        name: 'CashDesksEditForm',
        params: {
          id: cashDesk.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'CashDesksList'}).href,
          pathName: "CashDesksList"
        }
      })
    },
    handleEditDoubleClick(row, {item: cashDesk}) {
      this.handleEdit(cashDesk);
    },
    goToNew() {
      this.$router.push({
        name: 'CashDesksNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'CashDesksList'}).href
        }
      })
    },
    goToDeviceSettings() {
      this.$router.push({
        name: 'CashDesksDeviceSettings',
        params: {
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'CashDesksList'}).href,
          pathName: "CashDesksList"
        }
      })
    },
    async makeFiscalClosure(cashDesk) {
      if(!!cashDesk.printer){
        this.$delegates.snackbar('Chiusura fiscale in corso ...')

        Printer.fiscalClosure(`https://${cashDesk.printer}/cgi-bin/fpmate.cgi?devid=local_printer&timeout=10000`).then(() => {
          this.$delegates.snackbar('Chiusura fiscale eseguita con successo')
        }).catch(() => {
          this.$delegates.snackbar('Chiusura fiscale fallita')
        })
      } else
        this.$delegates.snackbar('Nessuna cassa aperta')
    },
    openScheduleFiscalClosureDialog(cashDesk) {
      this.cashDeskIP = cashDesk.printer
      this.scheduleFiscalClosureDialog = true
    },
    scheduleFiscalClosure() {
      this.scheduleFiscalClosureDialog = false
      
      Printer.scheduleFiscalClosure({
        time: this.scheduleTime,
        cashDeskIP: `https://${this.cashDeskIP}/cgi-bin/fpmate.cgi?devid=local_printer&timeout=10000`
      }).then(() => {
        this.$delegates.snackbar('Chiusura fiscale programmata con successo')
      }).catch(() => {
        this.$delegates.snackbar('Programmazione chiusura fiscale fallita')
      })
    },
    scheduleFiscalClosureReset(){
      this.scheduleFiscalClosureDialog = false
      
      Printer.scheduleFiscalClosureReset({
        cashDeskIP: `https://${this.cashDeskIP}/cgi-bin/fpmate.cgi?devid=local_printer&timeout=10000`
      }).then(() => {
        this.$delegates.snackbar('Chiusura fiscale resettata con successo')
      }).catch(() => {
        this.$delegates.snackbar('Reset chiusura fiscale fallita')
      })
    },
    handlePrintTextClick(cashDesk) {
      this.printCustomTextDialog = true
      this.printingCashDesk = cashDesk
    },
    async printCustomText() {
      this.printLoading = true
      if(!!this.printingCashDesk) {
        for(let i = 0; i < this.numberOfPrint; i += 1) {
          let sender = new Sender()
          sender.address = this.printingCashDesk.printer

          await sender.sendCustomText({ text: this.customText })
        }
      }
      this.printLoading = false
      this.printCustomTextDialog = false
      this.printingCashDesk = undefined
    }
  },
  computed: {
    filteredCashDesks() {
      return this.cashDesks
    },
    loading() {
      return this.loadingItems || this.loadingHeaders || this.loadingDelete
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>